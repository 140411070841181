import { useEffect, useState } from "react";
import { Link as LinkScroll } from 'react-scroll';
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { FaArrowUp } from "react-icons/fa";


export default function GoUp() {

  const [scrollPosition, setScrollPosition] = useState(0)

  // show full navbar
  const topOffset = 250
  const handleScroll = () => {
    setScrollPosition(window.pageYOffset)
  }
  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    window.addEventListener('touchend', handleScroll)
    return () => {
      window.removeEventListener('scroll', () => handleScroll)
      window.removeEventListener('touchend', () => handleScroll)
    }
  }, [])

  //TODO Corregir
  const getScrollPercentage = () => {
    if (typeof window !== 'undefined') {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.offsetHeight;
      const currentPosition = scrollPosition + windowHeight;
      const scrollPercentage = (currentPosition * 100) / documentHeight;
      return scrollPercentage;
    }
    return 0;
  };
  

  // console.log(getScrollPercentage())

  return (
    <LinkScroll to="main" smooth={true} offset={0} duration={800}>
      <button
        type="button"
        className="fixed p-0.5 bg-white border border-gray-300 rounded-full w-12 h-12 bottom-4 lg:bottom-6 left-4 lg:left-6 shadow-xl"
        style={{ zIndex: 500 }}
      >
        <CircularProgressbarWithChildren
          value={getScrollPercentage()}
          styles={buildStyles({
            pathColor: "white",
            trailColor: "white"
          })}
        >
          <div className="w-5">
            <FaArrowUp alt="Go to top" className="ml-0.5 h-5"/>
          </div>
        </CircularProgressbarWithChildren>
      </button>
    </LinkScroll>
  );
}
