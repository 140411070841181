export default {
  /////////////////////////////////////////////////////
  // BRAND
  /////////////////////////////////////////////////////
  projectName: 'Cuatro Pilares | Bienes Raíces',
  brand: {
    name: 'Cuatro Pilares',
    marketType: 'Bienes Raíces'
  },
  domain: 'cuatropilares.com.py',
  protocol: 'https',

  env: 'prod', // window.location.hostname === 'localhost' ? 'dev' : 'prod',

  currency: 'PYG',
  
  number: '595982200354',
  numberString: '(0982) 200 354',
  locationString: 'Del Maestro entre Teófilo Lesingg y Ntra. Sra. de la Asunción',
  schedule: 'Lunes a Viernes de 9:00 a 18:00hs',
  email: 'ejemplo@gmail.com',
  location: {lat: -25.3462287, lng: -57.5194769},
  locationLink: 'https://maps.app.goo.gl/NnLyJ14FznmQR5vZ7',
  instagram: 'https://www.instagram.com/cuatropilares.py',
  facebook: 'https://www.facebook.com/cuatropilares.py',

  webmailUrl: 'https://webmail.cuatropilares.com.py/',

  getURLprefix: function () {
    return `${this.protocol}://${this.domain}`;
  },

  getProductLink: function (id) {
    return `${this.getURLprefix()}/product/${id}`;
  },

  getCartLink: function (id) {
    return `${this.getURLprefix()}/cart/${id}`;
  },

  getWhatsAppText: function (id) {
    return `Hola! este es mi enlace de pedido ${this.getCartLink(id)}`;
  },

  whatsAppTextBTN: 'Hola!',

  imgFolder: 'productsImages',
  // el imgFolder y el fileName deben ser una sola cadena urlEncoded
  getImgPrefix: (fileName) => `https://firebasestorage.googleapis.com/v0/b/cuatro-pilares.appspot.com/o/productsImages%2F${fileName}?alt=media`,

  // youtube video tutorial embed id
  embedId: 'Do7B8xsvsVw', 

  /////////////////////////////////////////////////////
  // PLATFORM
  /////////////////////////////////////////////////////
  
  urlSeparator: '---',

  defaultLocationGPS: {
    "lat": -25.34337159646032,
    "lng": -57.50208590620728
  },

  imageDimensions: [
    { width: 2000, height: 2000, suffix: '' },   // full
    { width: 1300, height: 1300, suffix: '-xl' }, // xl
    { width: 800, height: 800, suffix: '-md' },   // md
    { width: 300, height: 300, suffix: '-xs' },   // xs
  ],

  googleMapApiKey: "AIzaSyBVsnosGIDa-GIMdQf5zPEn7KkSLnIBwZI"
}