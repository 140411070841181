import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { ToastProvider } from 'react-toast-notifications'
import { ProtectedRoute } from "./components/ProtectedRoute";
import { ModuleProvider } from "./context/ModuleContext";
import { AuthProvider } from "./context/AuthContext";
import { DataProvider } from "./context/DataContext";

import { PublicHome } from "./routes/PublicHome";

import { Login } from "./routes/Login";
import { Register } from "./routes/Register";

import { AdminHome } from "./routes/AdminHome";

import { AdminEntityCrud } from "./routes/AdminEntityCrud";
import { AdminEntityCrudForm } from "./routes/AdminEntityCrudForm";
import { AdminEntityCrudShow } from "./routes/AdminEntityCrudShow";
import { AdminEntityCreator } from "./routes/AdminEntityCreator";
import { AdminEntityCreatorShow } from "./routes/AdminEntityCreatorShow";
import { AdminEntityCreatorForm } from "./routes/AdminEntityCreatorForm";
import { AdminEntityCreatorTaxonomy } from "./routes/AdminEntityCreatorTaxonomy";
import { AdminEntityCreatorFilterMenu } from "./routes/AdminEntityCreatorFilterMenu";
import { AdminUserProfile } from "./routes/AdminUserProfile";

import { AdminOutstandingProducts } from "./routes/AdminOutstandingProducts";
import { FilterUrlSection } from "./routes/FilterUrlSection";
import { ItemShow } from "./routes/ItemShow";

import { AdminProducts } from "./routes/AdminProducts";
// import { AdminCarts } from "./routes/AdminCarts";
// import { AdminBrand } from "./routes/AdminBrand";
// import { CartShow } from "./routes/CartShow";
// import { ProductsShow } from "./routes/ProductsShow";

import { addLogEvent } from "./firebase";


function LogLocation ({ children }) {
  let location = useLocation();
  
  // log page
  useEffect(() => {
    setTimeout(() => {
      addLogEvent('page_view', {
        // page_location: location.href,
        page_path: location.pathname,
        page_title: document.title,
      }); // analytics
    }, 1000);
  }, [location]);

  return children;
}


function App() {
  return (
    <div className="text-black h-screen flex font-brand-main">
      <ToastProvider
      autoDismiss
      autoDismissTimeout={8000}
      placement="top-center">
        <AuthProvider>
          <ModuleProvider>
            <DataProvider>
              <LogLocation>
                <Routes>
                  {/* public */}
                  <Route path="/" element={<PublicHome />}/>
                  <Route path="/inmuebles/:itemId" element={<ItemShow />}/>
                  <Route path="/filter/*" element={<FilterUrlSection defaultPath={"/filter"} />}/>
                  <Route path="/login" element={<Login />} />
                  {/* <Route path="/register" element={<Register />} /> */}

                  {/* admin */}
                  <Route path="/admin" element={<ProtectedRoute><AdminHome /></ProtectedRoute>}/>
                  {/* User */}
                  <Route path="/admin/user/profile" element={<ProtectedRoute><AdminUserProfile /></ProtectedRoute>}/>
                  {/* Entity Crud */}
                  <Route path="/admin/entity/:entityId" element={<ProtectedRoute><AdminEntityCrud /></ProtectedRoute>}/>
                  <Route path="/admin/entity/:entityId/:docId" element={<ProtectedRoute><AdminEntityCrudShow /></ProtectedRoute>}/>
                  <Route path="/admin/entity/:entityId/:docId/form" element={<ProtectedRoute><AdminEntityCrudForm /></ProtectedRoute>}/>
                  {/* Entity Creator */}
                  <Route path="/admin/entity/creator" element={<ProtectedRoute><AdminEntityCreator /></ProtectedRoute>}/>
                  <Route path="/admin/entity/creator/:entityId/show" element={<ProtectedRoute><AdminEntityCreatorShow /></ProtectedRoute>}/>
                  <Route path="/admin/entity/creator/:entityId/form" element={<ProtectedRoute><AdminEntityCreatorForm /></ProtectedRoute>}/>
                  <Route path="/admin/entity/creator/:entityId/taxonomy" element={<ProtectedRoute><AdminEntityCreatorTaxonomy /></ProtectedRoute>}/>
                  <Route path="/admin/entity/creator/:entityId/filterMenu" element={<ProtectedRoute><AdminEntityCreatorFilterMenu /></ProtectedRoute>}/>

                  {/* legacy */}
                  <Route path="/admin/outstanding-products" element={<ProtectedRoute><AdminOutstandingProducts /></ProtectedRoute>}/>
                  {/* <Route path="/product/:productId" element={<ProductsShow />}/> */}
                  {/* <Route path="/cart/:shortId" element={<CartShow />} /> */}
                  {/* <Route path="/admin/products" element={<ProtectedRoute><AdminProducts /></ProtectedRoute>}/> */}
                  {/* <Route path="/admin/carts" element={<ProtectedRoute><AdminCarts /></ProtectedRoute>}/> */}
                  {/* <Route path="/admin/brand" element={<ProtectedRoute><AdminBrand /></ProtectedRoute>}/> */}
                </Routes>
              </LogLocation>
            </DataProvider>
          </ModuleProvider>
        </AuthProvider>
      </ToastProvider>
      <div className="hidden">
        <div className="bg-gray-300 text-gray-300 bg-blue-700 ring-blue-700 border-blue-700 text-blue-700 bg-stone-400 text-stone-400"></div>
      </div>
    </div>
  );
}

export default App;
