import EntityFilterMenuDisplay from '../EntityFilterMenuDisplay';

const entitySlug = "inmuebles";
const filterMenuSlug = "home";

export default function HeaderHeroBlock({ data }) { 
  return ( 
    <header id="inicio" className="py-20 lg:py-36 bg-cover bg-center relative" 
      style={{ backgroundImage: `url(${data.backgroundImageUrl})` }}> 

      {/* overlay */}
      <div className="absolute inset-0 bg-black opacity-60"></div>

      {/* hero */}
      <div className="px-5 text-white relative z-10 container-width">
        <img src="/logo-vertical-blanco.svg" alt={data.mainTitle} className=" h-44 md:h-60 relative mx-auto mb-4" />
        <h1 className="hidden">{data.mainTitle}</h1>
        <h2 className="text-center text-xl">{data.mainSubTitle}</h2>
      </div>

      {/* filters */}
      <div className="px-5 lg:px-20 pt-5 md:pt-10 mx-auto relative z-10 container-width">
      <EntityFilterMenuDisplay
        entitySlug={entitySlug}
        filterMenuSlug={filterMenuSlug}
        style={'search-compact'}
        classes={{ filterContainer: 'justify-center md:justify-start w-full md:w-auto' }}
      />
      </div>
    </header>
  )
}
