import { useEffect, useState } from "react";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import _ from "lodash";
import { LayoutPublic } from "../components/LayoutPublic";
import config from "../config";
import ItemDetailView from "../components/ItemDetailView";
import { blocks } from "../stringsMap";
// import SuperOutstandingProduct from "../components/Blocks/SuperOutstandingProduct";
// import GridOutstandingProductsBlock from "../components/Blocks/GridOutstandingProducts";
import CtaContactUsBlock from "../components/Blocks/CtaContactUs";
import CustomerReviewsBlock from "../components/Blocks/CustomerReviews";
import CtaImgFullBlock from "../components/Blocks/CtaImgFull";
import Model from "../libs/ModelClass";
import { animateScroll as scroll } from 'react-scroll';


const entitySlug = "inmuebles";

export function ItemShow () {
  const { itemId } = useParams();
  const [item, setItem] = useState(null);
  // const navigate = useNavigate();

  const ExtendedModel = Model.extend(entitySlug);

  const fetchItem = async () => {
    const item = await ExtendedModel.findById(itemId);
    setItem(item);
  };

  useEffect(() => {
    fetchItem();
  }, [itemId]);

  const getPageTitle = (suffix) => {
    // Resto de tu lógica para obtener el título de la página
    return suffix + " | " + itemId;
  };

  const HeaderMenu = () => (<>
    <Link
      to="/"
      title={config.brand.name}
      className="pl-10 md:px-3 hover:text-gray-400 text-white hover:underline hover:underline-offset-4 md:text-base xl:text-lg text-sm cursor-pointer"
      onClick={() => scroll.scrollToTop()}
    >
      Inicio
    </Link>
    <Link
      to="/filter"
      title="Explorar"
      className="pl-10 md:px-3 hover:text-gray-400 text-white hover:underline hover:underline-offset-4 md:text-base xl:text-lg text-sm cursor-pointer"
    >
      Explorar
    </Link>
  </>)

  return (
    <LayoutPublic HeaderMenu={HeaderMenu} logoAlt={true} >
      <MetaTags>
        <title>{getPageTitle(config.projectName)}</title>
        <meta name={getPageTitle()} content={config.projectName} />
      </MetaTags>

      {/* <div className="mt-5">
        <button type="button" className="px-2 py-2 w-32 text-left border rounded-lg shadow-md border-gray-200 bg-gray-50" onClick={() => {
          // GPT navigate a /filter
          navigate('/filter');
        }}>
          Buscar...
        </button>
      </div> 

      <hr className="border-gray-200 my-5" /> */}

      <div className="mt-10 md:px-8 lg:px-12 xl:px-48">
      {item ? (
        <ItemDetailView item={item} entitySlug={entitySlug} />
      ) : ''}

      </div>


      <div className="-mx-5 mt-20">
        <CtaContactUsBlock data={blocks.ctaContactUs} />

        {/* <SuperOutstandingProduct data={blocks.mainOutstandingProduct} /> */}

        {/* <GridOutstandingProductsBlock data={blocks.gridOutstandingProducts} /> */}

        <CustomerReviewsBlock data={blocks.customerReviews} />

        <CtaImgFullBlock data={blocks.ctaFooter} />
      </div>

    </LayoutPublic>
  );
}
