import { FaMapMarkerAlt, FaRegClock, FaWhatsapp } from "react-icons/fa";

export default function CtaContactUsBlock({ data }) {
  return (
    <div className="px-4 py-20 bg-gray-100 text-brand-dark text-center">
      {/* Left */}
      <div className="container-width">
        <h3 className="pb-2 text-xl md:text-2xl">{data.title}</h3>
        <h3 className="pb-2 text-3xl lg:text-4xl font-bold md:pb-4">{data.subtitle}</h3>
        <p className="pb-4 text-lg md:text-xl md:pb-6">{data.content}</p>
        
        <div className="grid gap-4 grid-flow-row grid-cols-2 md:grid-cols-4">
          {data.items.map((item, index) => (
            <div key={index} className="bg-white border-gray-200 border rounded-xl py-4 shadow-sm cursor-pointer hover:shadow-xl">
              <img src={item.iconUrl} className="h-8 pr-3 inline-block" />
              <a href={item.url} target="_blank" className="font-semibold">
                {item.title}
              </a>
            </div>
          ))}
        </div>

        <div className="grid gap-3 grid-cols-1 font-brand-montserrat text-base p-4 md:px-20 md:py-10">
          {data.phoneNumber ? (
            <div className="flex items-start text-center place-content-start align-top py-1 md:py-0">
              <div className="mr-1.5">
                <FaWhatsapp size={25}></FaWhatsapp>
              </div>
              <div className="font-semibold">Teléfono:</div>
              <div className="text-sm md:text-base ml-2 mt-1 md:mt-0">
                {data.phoneNumber}
              </div>
            </div>
          ) : null}

          {data.schedule ? (
            <div className="flex items-start text-center place-content-start align-top">
              <div className="mr-1.5">
                <FaRegClock size={25}></FaRegClock>
              </div>
              <div className="font-semibold">Horarios:</div>
              <div className="text-sm text-left md:text-base lg:text-base ml-2 mt-1 md:mt-0">
                {data.schedule}
              </div>
            </div>
          ) : null}

          {data.address ? (
            <div className="flex items-start text-center place-content-start align-top">
              <div className="mr-1.5">
                <FaMapMarkerAlt size={25}></FaMapMarkerAlt>
              </div>
              <div className="font-semibold">Ubicación:</div>
              <div className="text-sm text-left md:text-base lg:text-base ml-2 mt-1 md:mt-0">
                {data.address}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {/* Right */}
      <div className="">
        {/* [ img ] */}
      </div>
    </div>
  );
}
