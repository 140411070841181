import { FaQuoteLeft } from "react-icons/fa";

export default function CustomerReviewsBlock({ data }) {
  return (
    <div id="testimonios" className="px-4 py-20 bg-gray-100 text-brand-dark">
      <div className="container-width">
        {/* Secction description */}
        <div className="text-center">
          <h3 className="pb-2 text-xl">{data.title}</h3>
          <h3 className="pb-2 text-3xl font-bold">{data.subtitle}</h3>
          <p className="pb-4 text-lg">{data.content}</p>
        </div>
        {/* Testimonials */}
        <div className="mt-14">
          <div className="grid gap-4 grid-flow-row grid-cols-2">
            {data.items.map((item, index) => (
              <div key={index} className="review-item bg-white p-4 shadow-lg rounded-lg">
                <div className="review-title text-lg md:text-xl font-semibold flex flex-row items-center leading-tight">
                  <FaQuoteLeft className="text-xl mr-2.5 w-7"/>
                  {item.title}
                </div>
                <div className="mt-4 md:text-lg">{item.content}</div>
              </div>            
            ))}
          </div> 
        </div>
      </div>
    </div>
  );
}
