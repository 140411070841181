import { useState, useEffect } from "react";
import Product from "../../models/Product";
import ItemThumbGrid from "../ItemThumbGrid";

const entitySlug = "inmuebles";

export default function GridOutstandingProductsBlock({ data }) {
  const fetchOutstandingProducts = async () => {
    const productsAll = await Product.getAll();
    Product.sortDocs(productsAll, 'sort');
    const outstandingProduct = productsAll.filter((product) => product.data.outstanding);
    return outstandingProduct;
  };

  const [outstandingProduct, setOutstandingProduct] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const products = await fetchOutstandingProducts();
      setOutstandingProduct(products);
    };

    fetchProducts();
  }, []);

  console.log('outstandingProduct', outstandingProduct)
  return (
    outstandingProduct.length ? (
      <div className="px-4 pt-20 pb-24 bg-white text-brand-dark">
        <div className="container-width">
          <h3 className="pb-4 text-lg">{data.title}</h3>
          <ItemThumbGrid items={outstandingProduct} entitySlug={entitySlug} />
        </div>
      </div>
    ) : null
  );
}
