import { useEffect, useState, useRef } from "react";
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from "../context/AuthContext";
import config from "../config";
import { useScrollTo } from "../libs/utils";
import { animateScroll as scroll } from 'react-scroll';
import GoUp from "./GoUp";

export function LayoutPublic({ HeaderMenu, mainSectionClass, logoAlt=false, children }) {
  const { user } = useAuth();
  const [menuHeight, setMenuHeight] = useState();
  const ref = useRef();
  const location = useLocation();
  const { scrollToDelay } = useScrollTo();

  useEffect(() => {
    const handleResize = () => {
      setMenuHeight(ref.current.getBoundingClientRect().height);
    }

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  useEffect(() => {
    scrollToDelay('main');
  }, [location.pathname]);

  return (
    <div id="main" className="w-full text-black" style={{paddingTop: menuHeight}}>
      {/* header */}
      <header id="menu" ref={ref} className="fixed w-full top-0 bg-brand-dark shadow-md z-50 overflow-hidden">
        <div className="flex justify-between place-items-center px-5 py-4 mx-auto md:mx-8 lg:mx-16 xl:mx-48">
          
          <Link
            to="/"
            title={config.brand.name}
            className="block"
            onClick={() => scroll.scrollToTop()}
          >
            <h1 className="relative text-lg font-bold text-stone-800 md:text-2xl md:pt-0 flex flex-row" title={config.brand.name}>
              <img src={ logoAlt ? '/logo-horizontal-blanco.svg' : '/logo-icono-blanco.svg' } alt={config.brand.name} className={`${ logoAlt ? 'h-14 md:h-16 md:my-2' : 'h-10 sm:h-12 md:h-14' } relative`} />
            </h1>
          </Link>

          <div className="">
            {HeaderMenu ? (
              <div className="">
                <HeaderMenu menuHeight={menuHeight}></HeaderMenu>
                {user ? (
                  <Link
                  className="ml-4 bg-zinc-200 md:hidden hover:bg-zinc-300 rounded py-1.5 px-1.5 text-black text-sm"
                  to="/admin">
                    Admin
                  </Link>
                ) : ''}
              </div>
            ) : ''}
            {/* <Link
            className=" bg-zinc-200 hover:bg-zinc-300 rounded py-2 px-4 text-black text-sm"
            to="/">
              Explorar
            </Link> */}
          </div>
        </div>
      </header>
      
      {/* content */}
      <section className="layout-main-section-container min-h-screen">
        <div className={`layout-main-section z-10 min-h-screen ${mainSectionClass ? mainSectionClass : `px-5`}`}>
          {children}
          {/* <div className="max-w-none -mx-5 flex place-content-center pt-[100px] pb-[150px] relative overflow-hidden">
            <img src="/[logo].png" alt="logo" className="max-w-none spin-slow object-cover w-[600px] xs:w-[700px] sm:w-[800px] md:w-[800px]" />
          </div> */}
        </div>
      </section>

      <GoUp></GoUp>
    </div>
  );
}
