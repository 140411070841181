import config from './config';

export const blocks = {
  headerHero: {
    mainTitle: config.brand.name,
    mainSubTitle: "Tu socio confiable",
    backgroundImageUrl: "/header-1400.jpg"
  },
  textGridWithTitle: {
    title: "Nuestros valores",
    items: [
      {
        title: "Compromiso",
        content: "Nos comprometemos a brindarte el mejor servicio en todas tus transacciones inmobiliarias."
      },
      {
        title: "Profesionalismo",
        content: "Nuestro equipo de expertos en bienes raíces está dedicado a ofrecerte soluciones efectivas y asesoramiento de calidad."
      },
      {
        title: "Confianza",
        content: "Construimos relaciones sólidas basadas en la confianza mutua con nuestros clientes y socios."
      }
    ]
  },
  ctaAccordion: {
    title: "¿Qué podemos hacer por ti?",
    subtitle: "Explora nuestras opciones",
    items: [
      {
        title: "COMPRA",
        content: "Encuentra la propiedad perfecta para ti, ya sea una casa, un apartamento o un terreno.",
        ctaTitle: "Ver casas en venta",
        ctaType: "link",
        ctaUrl: "/filter/trato/venta"
        
        
      },
      {
        title: "VENDE",
        content: "Confía en nosotros para vender tu propiedad al mejor precio y en el menor tiempo posible.",
        ctaTitle: "Coordinar por WhatsApp",
        ctaType: "whatsapp",
        ctaUrl: `https://wa.me/${config.number}?text=Hola,%20estoy%20interesado%20en%20vender%20mi%20propiedad.%20Me gustaría obtener más información y asesoramiento experto.`,
        
      },
      {
        title: "ALQUILA",
        content: "Encuentra opciones de alquiler ideales para tus necesidades, ya sea a largo o corto plazo.",
        ctaTitle: "Ver alquileres",
        ctaType:"link",
        ctaUrl:"/filter/trato/alquiler"

        
      },
      {
        title: "UNITE AL EQUIPO",
        content: "Únete a nuestro equipo de profesionales apasionados por los bienes raíces y crece con nosotros.",
        ctaTitle: "Escríbenos al WhatsApp",
        ctaType:"whatsapp",
        ctaUrl: `https://wa.me/${config.number}?text=Hola,%20estoy%20interesado%20en%20unirme%20a%20su%20equipo.%20Me gustaría conocer más sobre las oportunidades disponibles.`,
      }
    ]
  },

  mainOutstandingProduct: {
    title: "Propiedad destacada",
    subtitle: "Premium"
  },
  gridOutstandingProducts: {
    title: "Propiedades destacadas",
    subtitle: "Premium"
  },
  ctaContactUs: {
    title: "Contáctanos",
    subtitle: "¡Estamos aquí para ayudarte!",
    address: config.locationString,
    phoneNumber: config.numberString,
    content: "Si tienes alguna consulta o necesitas más información, no dudes en contactarnos.",
    items: [
      {
        iconUrl: '/whatsapp.png',
        title: "WhatsApp",
        url: `https://wa.me/${config.number}?text=Hola,%20estoy%20interesado%20en%20obtener%20más%20información%20sobre%20sus%20servicios`
      },
      {
        iconUrl: '/phone-call.png',
        title: "Llamar",
        url: `tel:+${config.number}`
      },
      {
        iconUrl: '/email.png',
        title: "Email",
        url: `mailto:${config.email}?subject=Consulta%20sobre%20sus%20servicios&body=Hola,%20estoy%20interesado%20en%20obtener%20más%20información%20sobre%20sus%20servicios.`
      },
      {
        iconUrl: '/location.png',
        title: "Ubicación",
        url: config.locationLink
      }
    ]
  },
  customerReviews: {
    title: "Opiniones de nuestros clientes",
    subtitle: "Descubre lo que dicen",
    content: "Nuestros clientes valoran nuestro trabajo y compromiso. Aquí tienes algunas de sus opiniones.",
    items: [
      {
        title: "¡Excelente servicio!",
        content: "Encontré la casa de mis sueños gracias a Cuatro Pilares Bienes Raíces."
      },
      {
        title: "Increíble atención al cliente",
        content: "Siempre dispuestos a ayudar y encontrar soluciones."
      },
      {
        title: "Exelente proceso de venta",
        content: "Muy satisfecho con el proceso de venta de mi propiedad. Profesionales y eficientes."
      },
      {
        title: "Recomendado 100%",
        content: "Recomiendo Cuatro Pilares Bienes Raíces a todos los que buscan resultados excepcionales."
      }
    ]
  },
  frecuentAnswers: {
    title: "Preguntas frecuentes",
    items: [
      {
        title: "¿Cuáles son los documentos necesarios para comprar una propiedad?",
        content: "Para comprar una propiedad, necesitarás presentar una identificación válida, comprobante de ingresos y una carta de preaprobación de un banco o entidad financiera, entre otros documentos. Contáctanos para obtener más detalles."
      },
      {
        title: "¿Cuál es el plazo de alquiler mínimo?",
        content: "El plazo de alquiler mínimo depende del tipo de propiedad. En general, el plazo mínimo es de 6 meses, pero en algunos casos puede ser negociable. Te recomendamos hablar con nuestro equipo para conocer los detalles específicos de la propiedad que te interesa."
      },
      {
        title: "¿Cuánto tiempo se tarda en vender una propiedad?",
        content: "El tiempo necesario para vender una propiedad puede variar según diferentes factores, como el mercado inmobiliario actual, la ubicación y las características de la propiedad, así como el precio de venta. Nuestro equipo trabajará contigo para establecer una estrategia de venta efectiva y lograr resultados en el menor tiempo posible."
      },
      {
        title: "¿Cómo puedo unirme al equipo de Cuatro Pilares Bienes Raíces?",
        content: "Nos complace que estés interesado en unirte a nuestro equipo. Te recomendamos visitar nuestra página de carreras en nuestro sitio web, donde encontrarás información sobre las oportunidades de trabajo actuales y cómo enviar tu solicitud."
      }
    ]
  },
  ctaFooter: {
    title: "¡Convierte tus sueños en realidad!",
    urlDest: '',
    backgroundImageUrl: '/header-1400.jpg',
    followUs: "Encuéntranos en:",
    facebook: config.facebook,
    whatsapp: `https://wa.me/${config.number}?text=Hola,%20estoy%20interesado%20en%20obtener%20más%20información%20sobre%20sus%20servicios.`,
    instagram: config.instagram,
    copyright: `© 2023 ${config.brand.name} | Paraguay`,
  }
};