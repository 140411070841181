import { useState } from 'react';
import { Form } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import Modal from '../components/Modal';
import { FormField } from '../components/Form';


const SectionCrudForm = ({
  doc, // typeof Model
  editStyle, // onsite modal route
  onClose,
  onSave,
  fieldsRequired,
  onValidation = (() => null),
  FormInputFields, // ({form, values, handleSubmit, submitting, fieldsRequired}) => FormFields inputs
  showToast = true,
  showCloseButton = true
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const navigate = useNavigate();
  const { addToast } = useToasts();

  FormInputFields = FormInputFields || ((props) => (<>
    <FormField name="name" title="Nombre" placeholder="Nombre" {...props} />
  </>));

  const handleSubmit = async (values) => {
    setIsSaving(true);
    await onSave(values);
    setIsSaving(false);
    showToast && addToast('Se han guardado los datos', { appearance: 'success' });
  };

  const validateForm = (values) => {
    const errors = {};
    if (fieldsRequired && fieldsRequired.find(field => field === 'name')) {
      if (!values.name) {
        errors.name = ' ';
      }
    }
    onValidation(values, errors);
    setHasErrors(Object.keys(errors).length > 0);
    return errors;
  };

  const handleClose = () => {
    if (editStyle === 'modal' || editStyle === 'onsite') {
      onClose();
    } 
    else if (editStyle === 'route') {
      navigate(-1); // Navegar hacia atrás en la historia de navegación
    }
  };

  const renderContent = ({ form, values, handleSubmit, submitting }) => {    
    return (
      <form onSubmit={(event) => {
        // TODO: prevent submit on pressing enter on a field
        event.preventDefault(); // No funciona
      }}>
        {FormInputFields ? (<FormInputFields {...{form, values, handleSubmit, submitting, fieldsRequired}} />) : ''}
  
        <div className={`mt-5 grid grid-cols-2 gap-4 ${!showCloseButton ? '' : ''}`}>
          {showCloseButton ? (
            <button type="button" className="bg-gray-400 text-gray-100 rounded px-4 p-2 text-xl" onClick={handleClose}>
              {editStyle === 'route' ? 'Atrás' : 'Cerrar' }
            </button>
          ) : (
            <div />
          )}
          <button
            type="button"
            onClick={handleSubmit}
            className={`
              ${isSaving ? 'bg-blue-500 text-gray-100 opacity-50' : 'bg-blue-500 text-gray-100'}
              rounded px-4 p-2 text-xl
            `}
            disabled={submitting || isSaving}
          >
            {isSaving ? 'Guardando...' : 'Guardar'}
          </button>
        </div>
      </form>
    );
  }  
  

  return (
    <>
      {editStyle === 'modal' ? (
        <Modal title={doc && doc.id ? 'Editar' : 'Agregar'} open={true} setOpen={onClose}>
          <Form
            onSubmit={handleSubmit}
            initialValues={doc ? (doc.data || doc) : {}}
            validate={validateForm}
            render={renderContent}
          />
        </Modal>
      ) : (
        <div>
          <h2 className='text-xl font-semibold mb-4 mr-3'>
            {doc && doc.id ? 'Editar' : 'Agregar'}
          </h2>
          <Form
            onSubmit={handleSubmit}
            initialValues={doc ? (doc.data || doc) : {}}
            validate={validateForm}
            render={renderContent}
          />
        </div>
      )}
    </>
  );
};

export default SectionCrudForm;
