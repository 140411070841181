export default function TextGridWithTitleBlock({ data }) {
  return (
    <div className="px-4 py-20 bg-brand-dark text-white text-center">
      <div className="container-width">
        <h3 className="pb-10 text-3xl md:text-4xl font-bold">{data.title}</h3>
        <div className="grid gap-6 grid-flow-row grid-cols-2 md:grid-cols-3 md:gap-10">
          {data.items.map((item, index) => (
            <div key={index}>
              <h4 className="font-bold text-lg mb-2 underline">{item.title}</h4>
              <p>{item.content}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
