import { useState } from "react";
import Resizer from "react-image-file-resizer";
import slugify from 'slugify';
import config from "../config";
import _ from "lodash";


export const scrollTo = (id, moreOffsetPosition=0) => {
  const menuElement = document.getElementById('menu');
  if (menuElement) {
    // calc offset
    const headerOffset = 15 + menuElement.getBoundingClientRect().height;
    const element = document.getElementById(id);
    const elementPosition = element ? element.getBoundingClientRect().top : 0;
    const offsetPosition = elementPosition + window.scroolY - headerOffset - moreOffsetPosition;
  
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
      duration: 1000,
    });
  }
}


export const useScrollTo = () => {
  const [ scrollOnceList, setScrollOnceList ] = useState([]);

  const scrollOnceTo = (elementId, offsetPosition=0) => {
    if (!scrollOnceList[elementId]) {
      setTimeout(() => { 
        scrollTo(elementId, offsetPosition); 
      }, 400);
      setScrollOnceList({ 
        ...scrollOnceList, 
        [elementId]: true
      });
    }
  }

  const scrollToDelay = (elementId, offsetPosition=0, delay=400) => {
    setTimeout(() => { 
      scrollTo(elementId, offsetPosition); 
    }, delay);
  }
  
  return { 
    scrollOnceTo,
    scrollToDelay
  };
}


export const priceFormat = num => {
  if (num > 0) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' Gs';
  }
}

const WhatsAppURL = 'https://wa.me';


export const openWhatsApp = ({ number, message }) => {
  number = number.replace(/[^\w\s]/gi, '').replace(/ /g, '');

  let url = `${WhatsAppURL}/${number}`;

  if (message) {
    url += `?text=${encodeURI(message)}`;
  }

  window.open(url);
}


export const resizeFile = ({ file, width, height, format }) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file, width, height, format, 100, 0, 
      (uri) => {
        resolve(uri);
      },
      "file"
    );
  });
}


export const getImageURL = (fullImageURL, size) => {
  const [ hash, format ] = fullImageURL.split('.');
  return config.getImgPrefix(`${hash}-${size}.${format}`);
};


export const sendToLast = (list) => {
  if (list.length <= 1) { return list; }
  let newList = [ ...list ];
  newList.push(newList.shift())
  return newList;
}


export const slug = (text='') => {
  return slugify(text, { replacement: '_', lower: true, trim: true });
}

const nestedAttrSeparator = '__';

// Convertir los pares attr/val en un objeto
export function parseAttrValParams(attrValParams = '', separator = "/") {
  const attrValPairs = attrValParams.split(separator);
  const attrValObject = {};
  for (let i = 0; i < attrValPairs.length; i += 2) {
    let attr = attrValPairs[i];
    let val = attrValPairs[i + 1];
    if (val == parseInt(val, 10)) {
      val = parseInt(val, 10);
    }
    if (_.includes(val, nestedAttrSeparator)) {
      val = parseAttrValParams(val, nestedAttrSeparator);
    }
    attrValObject[attr] = val;
  }
  if (_.isEqual(attrValObject, {'': undefined})) {
    return {}; 
  }
  return attrValObject;
}

// convertir un objeto en pares attr/val en un string
export function stringifyAttrValParams(attrValObject, separator = "/") {
  const attrValPairs = [];
  for (let [attr, val] of Object.entries(attrValObject)) {
    if (!_.isArray(val) && _.isObject(val)) {
      val = stringifyAttrValParams(val, nestedAttrSeparator);
    }
    if (!_.isEmpty(val) || _.size(val) || (_.isBoolean(val) && val) || _.isString(val) || _.isNumber(val)) {
      attrValPairs.push(attr, val);
    }
  }
  return attrValPairs.join(separator);
}

/**
 * Combina los valores de cada objeto en un nuevo objeto, concatenando los valores correspondientes.
 * @param {...Object} layers - Lista variable de objetos que contienen los valores a combinar.
 * @returns {Array} - Un nuevo objeto que contiene los valores concatenados.
 *
 * @example
 * const layers = [
 *   { fieldContainer: 'text-xl', fieldLabel: 'label1' },
 *   { fieldContainer: 'p-2' },
 *   { fieldContainer: 'text-gray-200', fieldLabel: 'label2' }
 * ];
 *
 * const result = stackClasses(...layers);
 * console.log(result);
 * // Salida: { fieldContainer: 'text-xl p-2 text-gray-200', fieldLabel: 'label2' }
 */
export function stackClasses(...layers) {
  let result = {};

  for (const layer of layers) {
    for (const key in layer) {
      if (key in result) {
        result[key] += ` ${layer[key]}`;
      } else {
        result[key] = layer[key];
      }
    }
  }

  return result;
}


export const downloadJSON = (data, filename) => {
  const jsonData = JSON.stringify(data, null, 2);
  const blob = new Blob([jsonData], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  link.click();
  URL.revokeObjectURL(url);
};