import { useState } from 'react';

export default function FrecuentAnswersBlock({ data }) {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div id="preguntas" className="px-4 py-20 bg-white text-brand-dark">
      <div className="container-width">
        <h3 className="pb-4 text-3xl lg:pb-6 font-bold text-center">{data.title}</h3>
        <div className="grid gap-4 grid-flow-row grid-cols-1">
          {data.items.map((item, index) => (
            <div
              key={index}
              className={`cursor-pointer bg-white border-gray-200 border 
                py-3 px-4 rounded-xl
                shadow-sm hover:shadow-lg hover:border-gray-300 ${
                index === activeIndex ? 'active' : ''
              }`}
              onClick={() => handleAccordionClick(index)}
            >
              <div className="font-semibold md:text-lg">{item.title}</div>
              {index === activeIndex && <div className="my-1 lg:text-lg">{item.content}</div>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
