import { useState } from 'react';
import { nanoid } from 'nanoid';
import slugify from 'slugify';
import { Fragment } from 'react';

const ItemForm = ({ item, onChange, onDelete }) => {
  const handleLabelChange = (e) => {
    const label = e.target.value;
    const value = slugify(label, { lower: true });
    onChange({ ...item, label, value });
  };

  const handleDelete = () => {
    onDelete(item);
  };

  return (
    <div className="flex gap-2 items-center my-2">
      <input
        className="border-gray-200 border px-2 py-1 rounded-md flex-grow w-1/3"
        type="text"
        placeholder="Texto"
        value={item.label}
        onChange={handleLabelChange}
      />
      <input
        className="border-gray-200 border px-2 py-1 rounded-md w-1/3"
        type="text"
        placeholder="Valor"
        value={item.value}
        disabled
      />
      <button type="button" onClick={handleDelete} className="font-bold text-red-700">
        x
      </button>
    </div>
  );
};

const RawInputOptionListForm = ({ items: initialItems, onChange }) => {
  const [items, setItems] = useState(initialItems);
  const [listId] = useState(nanoid());

  const handleAddItem = () => {
    const newItem = { label: '', value: '' };
    const newItems = [...items, newItem];
    setItems(newItems);
    onChange(newItems);
  };

  const handleItemChange = (index, newItem) => {
    const updatedItems = [...items];
    updatedItems[index] = newItem;
    setItems(updatedItems);
    onChange(updatedItems);
  };

  const handleItemDelete = (itemToDelete) => {
    const updatedItems = items.filter((item) => item !== itemToDelete);
    setItems(updatedItems);
    onChange(updatedItems);
  };

  return (
    <div>
      {items.map((item, index) => (
        <Fragment key={`${listId}-${index}`}>
          <ItemForm
            item={item}
            onChange={(newItem) => handleItemChange(index, newItem)}
            onDelete={handleItemDelete}
          />
        </Fragment>
      ))}
      <button type="button" onClick={handleAddItem} className="bg-emerald-600 text-gray-100 rounded px-3 p-1 text-base">
        Agregar elemento
      </button>
    </div>
  );
};

export default RawInputOptionListForm;