import _ from 'lodash';
import { createContext, useContext, useEffect, useState } from "react";
import { boot } from '../modules/index';
import { useAuth } from "./AuthContext";
import { events as dataTypesEvents } from '../components/DataTypes';


const moduleContext = createContext();

export const useModule = () => {
  const context = useContext(moduleContext);
  if (!context) throw new Error("There is no Module provider");
  return context;
};

export function ModuleProvider({ children }) {
  const [modules, setModules] = useState();
  const [actionsByResource, setActionsByResource] = useState();
  const { user } = useAuth();
  const [rolesDoc, setRolesDoc] = useState(); // Agregar estado para rolesDoc

  useEffect(() => {
    if (!modules) {
      const { modules, actionsByResource } = boot();
      setModules(modules);
      setActionsByResource(actionsByResource);
    }
  }, [modules]);

  useEffect(() => {
    // Asignar el valor de rolesDoc cuando user.rolesDoc esté disponible
    if (user && user.rolesDoc) {
      setRolesDoc(user.rolesDoc);
    }
  }, [user]);

  const isAllowed = (resource, actions) => {
    if (!modules?.length || !rolesDoc) {
      return false;
    }
    let actionsToFire = _.isArray(actions) ? actions : [actions];
    return !!_.intersection(rolesDoc?.data?.permissions[resource], actionsToFire)?.length;
  };

  const fireEventReducer = async (event, param, result) => {
    if (!modules?.length || !rolesDoc) {
      return false;
    }
    let returnValue = result;
    // fire DataType listeners for all entities
    if (dataTypesEvents[event]) {
      returnValue = await dataTypesEvents[event](param, returnValue);
    };
    // fire Module listeners by entitySlug
    if (modules) {
      let { entitySlug } = param;
      for (let module of modules) {
        let listener = _.get(module, ['events', entitySlug, event]);
        if (listener) {
          returnValue = await listener(param, returnValue);
        }
      }
    };
    return returnValue;
  };

  return (
    <moduleContext.Provider
      value={{
        modules,
        actionsByResource,
        isAllowed,
        fireEventReducer
      }}
    >
      {modules && actionsByResource ? children : null}
    </moduleContext.Provider>
  );
}
