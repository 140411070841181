import { FaFacebook, FaWhatsapp, FaInstagram } from 'react-icons/fa';

export default function CtaImgFullBlock({ data }) {
  return (
    <div className="py-32 lg:py-36 bg-cover bg-center relative" 
      style={{ backgroundImage: `url(${data.backgroundImageUrl})` }}> 

      {/* overlay */}
      <div className="absolute inset-0 bg-black opacity-60"></div>

      {/* cta */}
      <div className="relative z-10 container-width text-center">
        <a href={data.urlDest} className="border-gray-200 bg-white text-brand-dark lg:text-lg border rounded-xl py-4 px-4 shadow-sm cursor-pointer hover:shadow-xl">
          {data.title}
        </a>
      </div>

      {/* links */}
      <div className="relative z-20 container-width text-white text-center mt-14 lg:mt-16">
      <span className="text-lg lg:text-xl">{data.followUs} </span>
        <div className="flex justify-center space-x-4 lg:space-x-6 text-4xl mt-3 mb-8 lg:mb-10">
          <a target='_blank' href={data.facebook}><FaFacebook /></a>
          <a target='_blank' href={data.whatsapp}><FaWhatsapp/></a>
          <a target='_blank' href={data.instagram}><FaInstagram/></a>
        </div>
        <span className="border-t lg:text-lg p-2">{data.copyright} </span>
      </div>

    </div>
  )
}