import { Link } from 'react-router-dom';
import _ from 'lodash';
import { LayoutAdmin } from "../components/LayoutAdmin";
import { useEntityFullBySlug } from '../modules/entity/Entity';
import SectionCrudForm from '../components/SectionCrudForm';
import { useAuth } from '../context/AuthContext';
import { EntityTaxonomyForm } from '../components/EntityTaxonomyForm';
import PartOfModule from '../components/Module/PartOfModule';
import Model from '../libs/ModelClass';

export function Content() {
  const { user } = useAuth();
  const entitySlug = 'usersProfiles';
  const UserModel = Model.extend(entitySlug);

  const { doc, taxonomyTypesDocList, fieldsRequired } = useEntityFullBySlug({ docId: user.userDoc.id, entitySlug });

  const handleSave = async (formValues) => {
    await UserModel.createOrUpdate(formValues);
  };
  
  const onValidation = (values, errors) => {
    // all required fields
    fieldsRequired.forEach(field => {
      if (!values[field]) {
        errors[field] = ' '; 
      }
    });
    // TODO realiar validación de taxonomías segú tipo y param
  };

  const FormInputFields = (({ values, fieldsRequired }) => (<>
    <EntityTaxonomyForm
      values={values}
      entitySlug={entitySlug}
      taxonomyTypesDocList={taxonomyTypesDocList}
      fieldsRequired={fieldsRequired}
    />
    <PartOfModule
      type="crud"
      action="UnderForm"
      entitySlug={entitySlug}
      param={{ values }} 
    />
  </>));

  return (
    <div className="p-3 rounded-md border border-gray-200 shadow-md hover:shadow-lg ">
      {/* form add */}
      {doc ? (
        <SectionCrudForm
          editStyle={'onsite'}
          doc={doc}
          onSave={handleSave}
          fieldsRequired={fieldsRequired}
          onValidation={onValidation}
          FormInputFields={FormInputFields}
          showToast={true}
          showCloseButton={false}
        />
      ) : null}
    </div>
  );
}


export function AdminUserProfile() {
  const { user } = useAuth();

  return (
    <LayoutAdmin>
      {/* path */}
      <div className="flex mb-5">
        <Link to="/admin"><h2 className="text-xl font-thin mb-4 mr-3">Configuraciones</h2></Link>
        <h2 className="text-xl font-semibold mb-4">Perfil de Usuario</h2>
      </div>

      {user?.userDoc?.id ? <Content/> : null}
    </LayoutAdmin>
  );
}
