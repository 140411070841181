import { useState } from 'react';
import { FormChangeSubscriber } from '../Form';
import { FormFiltersRender } from "./";
import { stringifyAttrValParams } from '../../libs/utils';
import { useNavigate } from "react-router-dom";

const MenuStyleCompact = (props) => {
  let {
    classes,
    handleSubmit
  } = props;

  let renderFilterParams = {
    showLabel: false
  };

  const [filterSelected, setFilterSelected] = useState({});
  const navigate = useNavigate();
  
  const onFormChange = (formValues) => {
    setFilterSelected(formValues);
    handleSubmit(formValues);
  };

  const onSearchClick = () => {
    const attrValParams = stringifyAttrValParams(filterSelected);
    const url = `/filter/${attrValParams}`;
    navigate(url);
  };

  return (
    <form onSubmit={handleSubmit} className={`${classes?.filterMenuContainer || 'flex flex-row gap-x-3 gap-y-0 flex-wrap'}`}>
      <FormChangeSubscriber onFormChange={onFormChange} />

      <FormFiltersRender
        {...props}
        showClearBtns={false}
        renderFilterParams={renderFilterParams}
        classes={{...classes, filterContainer: classes?.filterContainer || 'block' }} // classes al ultimo para sobreescribir props.classes
      />

      <div className="w-full">
        <button 
        onClick={onSearchClick} type="button"
        className="block mx-auto md:mx-0 text-lg font-semibold
          mt-3 h-11 px-4 py-2 mb-1 cursor-pointer rounded-xl 
          bg-brand-dark text-white hover:scale-105 hover:bg-brand-dark/90 
          shadow shadow-white/50 hover:shadow-lg hover:shadow-white/20 
        ">
          Hacer búsqueda
        </button>
      </div>
    </form>
  );
}

export default MenuStyleCompact;