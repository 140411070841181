import { LayoutAdmin } from "../components/LayoutAdmin";
import { Link } from 'react-router-dom';
import Model, { useStateResults } from "../libs/ModelClass";
import { useModule } from "../context/ModuleContext";
import PartOfModule from "../components/Module/PartOfModule";

export function AdminHome() {
  const { isAllowed } = useModule();
  const entities = useStateResults( Model.extend('entities') );

  // Admin sections
  const EntitySectionsLinks = () => {
    const Sections = entities && entities
      .filter((entityDoc) => !entityDoc.data?.deleted && isAllowed(entityDoc?.data?.nameSlug, ['list']))
      .map((entityDoc) => (
        <Link key={entityDoc.id} to={`/admin/entity/${entityDoc.id}`} className="bg-white p-4 font-semibold border border-gray-300 rounded shadow-md">
          {entityDoc.data.name}
        </Link>
      ));

    return Sections;
  }

  return (
    <LayoutAdmin>
      <div className="container-width mx-auto pt-10 text-brand-dark">

        <img src="/logo-vertical-color.svg" className=" h-32 md:h-48 relative mx-auto mb-20" />

        <div className="mb-10 grid grid-cols-2 gap-4 text-center">
          <PartOfModule
            type="main"
            action="AdminHomeBeforeLinksList"
            param={{ isAllowed }}
          />

          <Link to="/admin/user/profile" className="bg-white p-4 font-semibold border border-gray-300 rounded shadow-md">Mi Perfil</Link>
          
          <EntitySectionsLinks />

          {isAllowed('entities', ['list']) ? (
            <Link to="/admin/entity/creator" className="bg-white p-4 font-semibold border border-gray-300 rounded shadow-md">Entidades</Link>
          ) : null}

          <PartOfModule
            type="main"
            action="AdminHomeAfterLinksList"
            param={{ isAllowed }}
          />
        </div>


        {/* <div className="mt-[300px]">
          <button className="p-2 bg-red-100 text-red-700 rounded-sm" type="button" 
          onClick={async () => {
            await updateallFilesMetadata();
          }}>
            Actualizar metadatos
          </button>
        </div> */}
      </div>
    </LayoutAdmin>
  );
}