import styled from 'styled-components'

export const SliderContainer = styled.div`
  ${props => (props.zooming ? '' : 'overflow: hidden; border-radius: 8px;')}
`

export const Slider = styled.div`
  display: grid;
  grid-auto-flow: column;
  width: 100%;
  user-select: none;
  touch-action: pan-y;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const SlideOverlay = styled.div`
  position: relative;
  z-index: ${props => (props.inFront ? 20 : 0)};
`

export const Button = styled.button`
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  display: inline-block;
  border-radius: 15px;
  font-size: 14px;
  padding: 6px;
  letter-spacing: 1px;
  line-height: 1;
`

export const ButtonContainer = styled.div`
  position: relative;
  text-align: center;
  margin-top: -45px;
  z-index: ${props => (props.inFront ? '20' : '-1')};
`