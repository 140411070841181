import { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import _ from "lodash";
import { useData } from "../context/DataContext";
import { ProductExplorer } from '../components/ProductExplorer';
import { LayoutPublic } from "../components/LayoutPublic";
import { scrollTo } from "../libs/utils";
import config from "../config";
import ContactBTN from "../components/ContactBTN";
import HeaderHeroBlock from "../components/Blocks/HeaderHero";
import TextGridWithTitleBlock from "../components/Blocks/TextGridWithTitle";
import CtaAccordionBlock from "../components/Blocks/CtaAccordion";
import SuperOutstandingProduct from "../components/Blocks/SuperOutstandingProduct";
import GridOutstandingProductsBlock from "../components/Blocks/GridOutstandingProducts";
import CtaContactUsBlock from "../components/Blocks/CtaContactUs";
import CustomerReviewsBlock from "../components/Blocks/CustomerReviews";
import FrecuentAnswersBlock from "../components/Blocks/FrecuentAnswers";
import CtaImgFullBlock from "../components/Blocks/CtaImgFull";
import FooterMenuBlock from "../components/Blocks/FooterMenu";
import { blocks } from "../stringsMap";
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';

const HeaderMenu = ({ menuHeight }) => (<>
  <ScrollLink
    className="ml-1 py-2 px-2 md:px-6 text-gray-200 hover:text-gray-400 md:text-lg text-sm cursor-pointer"
    to="inicio"
    smooth={true}
    duration={500}
    spy={true}
    offset={-menuHeight}
  >
    Inicio
  </ScrollLink>
  <ScrollLink
    className="ml-1 py-2 px-2 md:px-6 text-gray-200 hover:text-gray-400 md:text-lg text-sm cursor-pointer"
    to="preguntas"
    smooth={true}
    duration={500}
    spy={true}
    offset={-menuHeight}
  >
    Preguntas
  </ScrollLink>
  <ScrollLink
    className="ml-1 py-2 px-2 md:px-6 text-gray-200 hover:text-gray-400 md:text-lg text-sm cursor-pointer"
    to="testimonios"
    smooth={true}
    duration={500}
    spy={true}
    offset={-menuHeight}
  >
    Testimonios
  </ScrollLink>
</>)

export function PublicHome () {  

  // menuFooter: {}
  
  return (
    <LayoutPublic mainSectionClass="px-0" HeaderMenu={HeaderMenu} >
      {/* Header Hero */}
      <HeaderHeroBlock data={blocks.headerHero} />
      
      <TextGridWithTitleBlock data={blocks.textGridWithTitle} />

      <SuperOutstandingProduct data={blocks.mainOutstandingProduct} />

      <GridOutstandingProductsBlock data={blocks.gridOutstandingProducts} />

      <CtaAccordionBlock data={blocks.ctaAccordion} />

      <CtaContactUsBlock data={blocks.ctaContactUs} />

      <CustomerReviewsBlock data={blocks.customerReviews} />

      <FrecuentAnswersBlock data={blocks.frecuentAnswers} />

      {/* Footer */}

      <CtaImgFullBlock data={blocks.ctaFooter} />

      {/* <FooterMenuBlock data={blocks.menuFooter} /> */}

      {/* fixed footer */}
      <footer className="footer fixed bottom-0 left-0 w-full z-50">
        <div className="flex flex-row-reverse place-content-between mx-auto mb-4 mr-4">
          <ContactBTN className={'ml-2 mb-1 lg:mb-2 bg-brand-dark'} />
        </div>
      </footer>
    </LayoutPublic>
  );
}