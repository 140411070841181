import { Field } from 'react-final-form';
import _ from 'lodash';
import { useState, useEffect } from 'react';

import { required, RequiredByFieldsList, ShowError } from './formUtils';


const FormFieldCheckbox = ({ name, title, className, fieldsRequired=null, validate=null, disabled=false, formatter=null }) => {
  if (!formatter) {
    formatter = (value) => value
  }

  return (
    <div className={`pb-3 ${className}`}>
      <Field name={name} validate={(args) => validate && validate(args)}>
        {({ input, meta }) => (<>
          <div>
            <label position="floating">
              <span className={`font-semibold mr-3 ${meta.touched && meta.error ? 'text-red-600' : 'text-gray-900'}`}>
                {title}{(meta.touched && meta.error || (_.includes(fieldsRequired, name) && !input.value)) ? '*' : ''}
              </span>
            </label>
            
            <label className="px-2 py-1 rounded-md">
              <input
                type="checkbox"
                checked={formatter(input.value) ? true : false} 
                onChange={input.onChange}
                disabled={disabled}
                className="w-5 h-5 top-2 relative"
              />
            </label>
            {/* error */}
            <ShowError name={name} input={input} meta={meta} fieldsRequired={fieldsRequired} />
          </div>
        </>)}
      </Field>
    </div>
  );
}

export default FormFieldCheckbox;