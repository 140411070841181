import config from '../../config';
import permissionsDataType from './permissionsDataType';

const UnderListItemTitleForCredentials = ({ ViewData }) => (
  <ViewData field="roles" classes={{ fieldContainer: 'text-xs text-white bg-gray-600 px-2 py-0.5 rounded-full inline-block' }} />
);

const UnderListItemTitleForUsersProfiles = ({ ViewData }) => (
  <ViewData field="email" classes={{ fieldContainer: 'text-sm text-gray-800' }} />
);

const AdminHomeAfterLinksList = ({ isAllowed }) => {
  return (<>
    {isAllowed('actions', 'btn-webmail') ? (
      <a href={config.webmailUrl} target='_blank' className="bg-gray-300 text-gray-900 p-4 font-semibold border border-gray-600 rounded shadow-md">
        Email
      </a>
    ) : null}
  </>);
};

export default function ({ setPermissions }) {
  return {
    slug: 'user',
    label: 'User',
    permissions: [
      setPermissions({ slug: 'credentials', label: 'Credenciales', type: 'system' }),
      setPermissions({ slug: 'usersProfiles', label: 'Usuarios', type: 'system' }),
      setPermissions({ slug: 'roles', label: 'Cargos', type: 'system' }),
      setPermissions({ slug: 'actions', label: 'Acciones', type: 'system', actions: ['btn-webmail'] })
    ],
    components: {
      'main': {
        AdminHomeAfterLinksList
      },
      'credentials': {
        crud: {
          UnderListItemTitle: UnderListItemTitleForCredentials
        }
      },
      'usersProfiles': {
        crud: {
          UnderListItemTitle: UnderListItemTitleForUsersProfiles
        }
      },
      'roles': {
        codedDataTypes: {
          permissions: permissionsDataType // RenderFormField
        }
      },
    },

    // events: {
    //   'usersProfiles': {
    //     beforeSave: async ({ taxonomyTypesDocList }, formValues) => {
    //       let modifiedFormValues = formValues;
    //       return modifiedFormValues;        
    //     }
    //   }
    // }
  };
};