import { useState } from 'react';
import { FaWhatsapp } from "react-icons/fa";

export default function CtaAccordionBlock({ data }) {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="px-4 py-20 bg-gray-100 text-brand-dark text-center">
      <div className="container-width grid gap-4 grid-flow-row grid-cols-1 md:grid-cols-2">
        {/* Left */}
        <div className="pb-5 md:pt-10 md:text-left">
          <h3 className="font-bold text-lg">{data.title}</h3>
          <h3 className="text-3xl md:text-5xl font-normal">{data.subtitle}</h3>
          {/* img */}
        </div>
        {/* Right */}
        <div className="grid gap-4 grid-flow-row grid-cols-1 md:grid-cols-1">
          {data.items.map((item, index) => (
            <div key={index} className="cursor-pointer bg-white border-gray-200 border py-4 shadow-sm hover:shadow-lg hover:border-gray-300 rounded-xl">
              <div
                className={`accordion-item ${index === activeIndex ? 'active' : ''}`}
                onClick={() => handleAccordionClick(index)}
              >
                <div className="accordion-title font-semibold md:text-lg cursor-pointer">
                  {item.title}
                </div>
                {index === activeIndex && (
                  <div className="accordion-content px-4 py-2 md:text-lg">
                    {item.content}
                    <div className='flex mt-4 justify-center'>
                      {item.ctaType === "whatsapp" && (
                        <a href={item.ctaUrl} target="_blank" className={`
                          bg-brand-dark rounded-lg text-white px-2 py-1 md:text-lg flex items-center hover:bg-lime-500
                        `}>
                          <FaWhatsapp className="text-xl text-white mr-1.5" />
                          {item.ctaTitle}
                        </a>
                      )}
                      {item.ctaType === "link" && (
                        <a href={item.ctaUrl} target="_blank" className={`
                          bg-brand-dark rounded-lg text-white px-2 py-1 md:text-lg hover:bg-slate-600
                        `}>
                          {item.ctaTitle}
                        </a>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
